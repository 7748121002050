<template>
  <div class="content">
    <a-card class="elCard">
      <div slot="title" class="card-title">
        <a-button size="small" @click="$router.go(-1)" style="margin-right: 15px">
          <a-icon type="left" />返回
        </a-button>
        <span>详情</span>
      </div>
      <a-form-model ref="form" :model="form" layout="inline"  :labelCol="{ span: 20, flex: '110px' }"
              :wrapperCol="{ span: 24 }">
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="用户名称" prop="nickname">
              <a-input readOnly v-model="form.nickname" placeholder="请输入用户名称" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="护照姓名" prop="passportName">
              <a-input readOnly v-model="form.passportName" placeholder="请输入护照姓名" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="状态" prop="status">
              <a-input readOnly v-if="form.status == -1" value="递签失败"></a-input>
              <a-input readOnly v-if="form.status == 1" value="准备资料中"></a-input>
              <a-input readOnly v-if="form.status == 2" value="递签中"></a-input>
              <a-input readOnly v-if="form.status == 3" value="已完成"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="申请类型" prop="applyType">
              <a-input readOnly v-if="form.applyType == 1" value="美国签证"></a-input>
              <a-input readOnly v-if="form.applyType == 2" value="申根签证"></a-input>
            </a-form-model-item>
          </a-col>

        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="申请区域" prop="schengenArea">
              <a-input readOnly v-if="form.schengenArea == 1" value="国内"></a-input>
              <a-input readOnly v-if="form.schengenArea == 2" value="英国"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="是否已婚" prop="isMarried">
              <a-input readOnly v-if="form.isMarried == 0" value="否"></a-input>
              <a-input readOnly v-if="form.isMarried == 1" value="是"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="状态" prop="currentStatus">
              <a-input readOnly v-if="form.currentStatus == 1" value="我已工作"></a-input>
              <a-input readOnly v-if="form.currentStatus == 2" value="我是学生"></a-input>
              <a-input readOnly v-if="form.currentStatus == 3" value="无业/自由职业"></a-input>
              <a-input readOnly v-if="form.currentStatus == 4" value="个体户"></a-input>
              <a-input readOnly v-if="form.currentStatus == 5" value="企业主"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="是否约到签证slot" prop="visaSlot">
              <a-input readOnly v-if="form.isMarried == 0" value="否"></a-input>
              <a-input readOnly v-if="form.isMarried == 1" value="是"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="申根国家" prop="applyCountry">
              <a-input readOnly :value="form.applyCountry"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="递签地点" prop="submitPlace">
              <a-input readOnly :value="form.submitPlace"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="递签时间" prop="submitTime">
              <a-date-picker disabled v-model="form.submitTime" format="yyyy-MM-DD HH:mm" style="width:100%"/>
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="提醒时间" prop="noticeTime">
              <a-date-picker disabled v-model="form.noticeTime" format="yyyy-MM-DD HH:mm" style="width:100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="收到签证时间" prop="receiveTime">
              <a-date-picker disabled v-model="form.receiveTime" format="yyyy-MM-DD HH:mm" style="width:100%"/>
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="签证有效时长" prop="validTime">
              <a-input readOnly v-model="form.validTime" />{{form.timeUnit}}
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="失败原因" prop="failReason" v-if="form.status == -1">
              <a-input readOnly v-model="form.failReason" />
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="护照号码" prop="passportNumber" >
              <a-input readOnly v-model="form.passportNumber" placeholder="请输入内容"  allow-clear style="width:100%"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">

          <a-col :md="6" :sm="24">
            <a-form-model-item label="logo" prop="logo" >
              <img  v-viewer  style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="form.logo"
                    :src="form.logo" preview="头像"/>
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="名字" prop="userName">
              <a-input readOnly v-model="form.userName" placeholder="请输入内容" allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="出生日期" prop="birthday" >
              <a-date-picker disabled style="width: 100%" v-model="form.birthday" format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="公司名称" prop="companyName" v-if="form.currentStatus == 1 || form.currentStatus == 5">
              <a-input readOnly v-model="form.companyName" placeholder="请输入内容"  allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="工作职位" prop="jobPosition" v-if="form.currentStatus == 1 || form.currentStatus == 5">
              <a-input readOnly v-model="form.jobPosition" placeholder="请输入内容"  allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="入职时间" prop="hiredate" v-if="form.currentStatus == 1 || form.currentStatus == 5">
              <a-date-picker disabled style="width: 100%" v-model="form.hiredate" format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="税前工资" prop="grossWage" v-if="form.currentStatus == 1 || form.currentStatus == 5">
              <a-input readOnly v-model="form.grossWage" placeholder="请输入税前工资" />{{ form.unit }}
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="公司电话" prop="companyMobile" v-if="form.currentStatus == 1 || form.currentStatus == 5">
              <a-input  readOnly v-model="form.companyMobile" placeholder="请输入公司" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="公司地址" prop="companyAddress" v-if="form.currentStatus == 1 || form.currentStatus == 5">
              <a-input readOnly v-model="form.companyAddress" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">

          <a-col :md="6" :sm="24">
            <a-form-model-item label="商户名称" prop="storeName" v-if="form.currentStatus == 4">
              <a-input readOnly v-model="form.storeName" placeholder="请输入商户电话" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="商户电话" prop="storeMobile" v-if="form.currentStatus == 4">
              <a-input readOnly v-model="form.storeMobile" placeholder="请输入商户电话" />
            </a-form-model-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-model-item label="商户地址" prop="storeAddress" v-if="form.currentStatus == 4">
              <a-input readOnly v-model="form.storeAddress" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="成立年份" prop="establishYear" v-if="form.currentStatus == 4">
              <a-input readOnly v-model="form.establishYear" placeholder="请输入成立年份" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="学校" prop="schoolName" v-if="form.currentStatus == 2">
              <a-input readOnly v-model="form.schoolName" placeholder="请输入内容"  allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="专业" prop="profession" v-if="form.currentStatus == 2">
              <a-input readOnly v-model="form.profession" placeholder="请输入内容"  allow-clear />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="出发时间" prop="departureTime" >
              <a-date-picker disabled style="width: 100%" v-model="form.departureTime" format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="目的地" prop="destination" >
              <a-input readOnly v-model="form.destination" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="出发城市" prop="departureCity" >
              <a-input readOnly v-model="form.departureCity" placeholder="请输入内容" allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="出发国家" prop="departureCountry" >
              <a-input readOnly v-model="form.departureCountry" placeholder="请输入内容"  allow-clear />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="返程国家" prop="returnCountry">
              <a-input readOnly v-model="form.returnCountry" placeholder="请输入内容" allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="返程城市" prop="returnCity" >
              <a-input v-model="form.returnCity" placeholder="请输入内容" readOnly allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="返程时间" prop="returnTime" >
              <a-date-picker disabled style="width: 100%" v-model="form.returnTime" format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="出行目的" prop="tripPurpose" >
              <a-input v-model="form.tripPurpose" placeholder="请输入内容" readOnly allow-clear />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="财务证明" prop="financialCertificate" >
              <a-input v-model="form.financialCertificate" placeholder="请输入内容" readOnly allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="回国保证" prop="returnGuarantee" >
              <a-input v-model="form.returnGuarantee" placeholder="请输入内容" readOnly allow-clear />
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- <div class="bottom-control">
          <a-space>
            <a-button type="dashed" @click="cancel"> 取消 </a-button>
          </a-space>
        </div> -->
      </a-form-model>

      <!-- table -->
      <Table :apply-id="this.id"/>
    </a-card>
  </div>
</template>

<script>
import { getVisaApply, addVisaApply, updateVisaApply } from '@/api/user/visaApply'
import Table from './Table.vue'
import moment from 'moment'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    Table,
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      id:null,
      // 表单参数
      form: {
        id: null,
        userId: null,
        passportName: null,
        status: 0,
        applyType: null,
        schengenArea: null,
        isMarried: null,
        currentStatus: 0,
        visaSlot: null,
        failReason: null,
        applyCountry: null,
        submitPlace: null,
        submitTime: null,
        noticeTime: null,
        receiveTime: null,
        validTime: null,
        timeUnit:null,
        logo: null,
        applyId:null,
        userName: null,
        birthday: null,
        passportNumber: null,
        companyName: null,
        jobPosition: null,
        schoolName: null,
        hiredate: null,
        grossWage: null,
        unit: null,
        companyMobile: null,
        storeMobile: null,
        storeName:null,
        companyAddress: null,
        storeAddress:null,
        establishYear: null,
        profession: null,
        departureTime: null,
        destination: null,
        departureCity: null,
        departureCountry: null,
        returnCountry: null,
        returnCity: null,
        returnTime: null,
        tripPurpose: null,
        financialCertificate: null,
        returnGuarantee: null,
        coverLetterUrl: null,
        itinerary: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [{ required: true, message: '用户ID｛user.id｝不能为空', trigger: 'blur' }],
        status: [
          { required: true, message: '状态 1-准备资料中 2-递签中 3-已完成 -1-递签失败不能为空', trigger: 'blur' },
        ],
        applyType: [{ required: true, message: '申请类型 1-美国签证 2-申根签证不能为空', trigger: 'change' }],
        visaSlot: [{ required: true, message: '是否约到签证slot 0-否 1-是不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {},
  created() {
    this.handleUpdate(this.$route.query.id)
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.id = null
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        passportName: null,
        status: 0,
        applyType: null,
        schengenArea: null,
        isMarried: null,
        currentStatus: 0,
        visaSlot: null,
        failReason: null,
        applyCountry: null,
        submitPlace: null,
        submitTime: null,
        noticeTime: null,
        receiveTime: null,
        validTime: null,
        timeUnit:null,
        logo: null,
        userName: null,
        birthday: null,
        applyId:null,
        passportNumber: null,
        companyName: null,
        jobPosition: null,
        schoolName: null,
        hiredate: null,
        grossWage: null,
        unit: null,
        companyMobile: null,
        storeMobile: null,
        storeName:null,
        companyAddress: null,
        storeAddress:null,
        establishYear: null,
        profession: null,
        departureTime: null,
        destination: null,
        departureCity: null,
        departureCountry: null,
        returnCountry: null,
        returnCity: null,
        returnTime: null,
        tripPurpose: null,
        financialCertificate: null,
        returnGuarantee: null,
        coverLetterUrl: null,
        itinerary: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.open = true
      this.reset()
      this.id = id
      getVisaApply({ id: id }).then((response) => {
        this.form = response.data
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVisaApply(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addVisaApply(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style scoped lang="less">
.content {
  width: 100% !important;
  height: 100% !important;
}

/deep/.elCard {
  .ant-card-body {
    height: 100% !important;
  }
}

/deep/.ant-form-item-label {
  text-align: left !important;
}
</style>
