import request from '@/utils/request'


// 查询签证申请记录列表
export function listVisaApply(query) {
  return request({
    url: '/user/visaApply/list',
    method: 'get',
    params: query
  })
}

// 查询签证申请记录分页
export function pageVisaApply(query) {
  return request({
    url: '/user/visaApply/page',
    method: 'get',
    params: query
  })
}

// 查询签证申请记录详细
export function getVisaApply(data) {
  return request({
    url: '/user/visaApply/detail',
    method: 'get',
    params: data
  })
}

// 新增签证申请记录
export function addVisaApply(data) {
  return request({
    url: '/user/visaApply/add',
    method: 'post',
    data: data
  })
}

// 修改签证申请记录
export function updateVisaApply(data) {
  return request({
    url: '/user/visaApply/edit',
    method: 'post',
    data: data
  })
}

// 删除签证申请记录
export function delVisaApply(data) {
  return request({
    url: '/user/visaApply/delete',
    method: 'post',
    data: data
  })
}
